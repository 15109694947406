import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import { DiscountsList } from 'layouts';
import { GET_LAST_DEAL_DEPARTURES } from 'actions/tours';
import { BASE_URL } from 'config';

class DiscountsPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      departures: [],
      loading: true,
    };
  }

  componentDidMount() {
    (async () => {
      // Get departures fresh!
      const DEPARTURES = await GET_LAST_DEAL_DEPARTURES();
      if (DEPARTURES && DEPARTURES.success) {
        this.setState({
          departures: DEPARTURES.data,
          loading: false,
        });
      }
    })();
  }


  /**
   * Render
   */
  render() {
    const { departures, loading } = this.state;

    return (
      <Layout page="discounts">
        <Helmet
          title="New Zealand Holiday Deals | NZ Travel Deals | Haka Tours"
          meta={[
            {
              name: 'description',
              content: `Join the trip of a lifetime: adventure, snow or private tours of NZ.
              View our New Zealand vacation packages online & book your New Zealand travel deal now!`,
            },
            {
              name: 'og:title',
              content:
                'New Zealand Holiday Deals | NZ Travel Deals | Haka Tours',
            },
            {
              name: 'og:description',
              content: `Join the trip of a lifetime: adventure, snow or private tours of NZ.
              View our New Zealand vacation packages online & book your New Zealand travel deal now! `,
            },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/discounts/` }]}
        />
        <DiscountsList list={departures} loading={loading} />
      </Layout>
    );
  }
}

export default DiscountsPage;
